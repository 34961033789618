/**
 * Capitalizes the first letter of a string
 * @param string - The string to capitalize
 * @returns The string with first letter capitalized, or empty string if input is undefined
 */
export function capitalizeFirstLetter(string?: string) {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Converts a hyphenated string to title case by splitting on hyphens,
 * capitalizing each word, and joining with spaces
 * @param value - The hyphenated string to convert
 * @returns The converted title case string
 */
export function convertToTitle(value: string) {
  return value
    .split('-')
    .map((word: string) => capitalizeFirstLetter(word))
    .join(' ');
}

/**
 * Slugify a string by converting it to lowercase, replacing spaces and special characters with hyphens,
 * replacing '&' with 'and', removing apostrophes, and cleaning up extra hyphens
 * @param value - The string to slugify
 * @returns The slugified string
 */
export function slugify(value: string) {
  return (
    value
      .trim()
      .toLowerCase()
      // Replace & with '-and-'
      .replaceAll('&', '-and-')
      // Remove apostrophes
      .replaceAll("'", '')
      // Replace any non-alphanumeric characters (except hyphens) with hyphens
      .replaceAll(/[^\d\sa-z-]/g, '-')
      // Replace one or more spaces or hyphens with a single hyphen
      .replaceAll(/[\s-]+/g, '-')
      // Remove leading and trailing hyphens
      .replaceAll(/^-/g, '')
      .replaceAll(/-$/g, '')
  );
}
