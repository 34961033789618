export enum UserRegistrationType {
  Free = 'free',
  Professional = 'professional',
  Emergn = 'emergn',
  Beta = 'beta',
  Enterprise = 'enterprise',
}

export type UserRegistrationConfiguration = {
  type?: UserRegistrationType;
  isActive: boolean;
  is2FaEnabled?: boolean;
};
