'use client';

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import ReCAPTCHA from 'react-google-recaptcha';

import { IdsLink, IdsButton, IdsCheckbox, IdsFieldWrapper, IdsText, IdsButtonGroup } from '@emergn-infinity/ids-react';

import { Preferences } from '@/interfaces/account-page/preferences';
import { User } from '@/interfaces/user';
import { AppRoutes } from '@/constants';
import { ApiMethods, ErrorMessage } from '@/constants/api';
import { useApi, useAppDispatch, useAppSelector } from '@/hooks';
import { setUser } from '@/redux/user-slice';
import { AuthToken } from '@/services/auth.service';
import { identifyUser, removeHeap } from '@/services/heap.service';
import { useNotification } from '@/providers/notification-context';
import { deleteTokenFromCookies, saveTokenOnCookies } from '@/server-actions/token';
import { login } from '@/server-actions/auth';
import { createHeadersJson } from '@/services/request.service';
import { slugify } from '@/helpers/strings';

import FormWrapper from '@/components/common/form-wrapper';
import VFQTextField from '@/components/vfq-text-field/vfq-text-field';
import VFQPasswordField from '@/components/vfq-password-field/vfq-password-field';
import TermsOfUse from '@/components/terms-of-use/terms-of-use';

import './main.scss';

const auth = new AuthToken();
const headers = new Headers({
  'Content-Type': 'application/json',
});

const handleHeap = (userWithType: User, cookiePreferences: Preferences) => {
  if (cookiePreferences?.analytics) {
    identifyUser(userWithType);
  } else {
    removeHeap();
  }
};

const handleLogout = async () => {
  await Promise.all([auth.logout(true), deleteTokenFromCookies()]);
};

type SignInMainProps = {
  cookiePreferences: Preferences;
  newSession: boolean;
};

const SignInMain: React.FC<SignInMainProps> = ({ cookiePreferences, newSession }) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const router = useRouter();
  const dispatch = useAppDispatch();

  const { sendRequest } = useApi();
  const { openErrorNotification } = useNotification();

  const { user } = useAppSelector((state) => state.user);

  const captchaRef = useRef<ReCAPTCHA>();
  const captchaSiteKey = process.env.captchaSiteKey;

  useEffect(() => {
    if (auth.hasValidToken()) {
      if (newSession || !user?.isActivated) {
        handleLogout();
      } else {
        loginUser();
      }
    }
  }, [user?.isActivated]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRecaptcha(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const staySignedIn = useMemo(() => auth.checkStaySignIn(), []);

  const getRedirectPath = (user: User) => {
    const { isBetaUser, isEmergnUser } = user.type || {};
    const isB2cUser = Object.values(user.type?.isB2cUser || {}).some(Boolean);

    const has2FA = user.is2FaEnabled?.user || user.is2FaEnabled?.client;
    const userTotpAuth = user.settings?.twoFactorAuthentication?.find((auth) => auth.type === 'totp' && auth.enabled);

    if (has2FA) {
      if (!user.learnerInformation) {
        return AppRoutes.SignUpProfessionalDetails;
      }

      return userTotpAuth?.secret
        ? AppRoutes.SignInTwoFactorAuthenticationVerification
        : AppRoutes.TwoFactorAuthenticationSetup;
    }

    if (!user.learnerInformation) {
      return AppRoutes.SignUpProfessionalDetails;
    }

    if (isBetaUser || isEmergnUser || isB2cUser) {
      return AppRoutes.ExploreForYou;
    }

    return AppRoutes.KanbanBoard;
  };

  const moveUserForward = async (user: User) => {
    const redirectTo = getRedirectPath(user);
    router.push(redirectTo);
  };

  const loginUser = async () => {
    try {
      let userToSignIn = user;

      // If the user doesn't exist, we need to get the user from the database
      if (!userToSignIn?.id) {
        const res = await sendRequest(
          'auth/get-user-by-id',
          ApiMethods.POST,
          JSON.stringify({ userId: auth.userId }),
          createHeadersJson(),
        );

        if (res.statusCode === 401 || !res.data?.user) {
          await Promise.all([auth.logout(), deleteTokenFromCookies()]);
          return;
        }

        userToSignIn = { ...res.data.user, type: res.data.userType, is2FaEnabled: res.data.is2FaEnabled };

        dispatch(setUser(userToSignIn));
      }

      moveUserForward(userToSignIn);
    } catch {
      openErrorNotification(ErrorMessage.SOMETHING_WENT_WRONG);
    }
  };

  const verifyCaptcha = async () => {
    if (!auth.checkLoginAttempts) {
      return true;
    }

    const token = await captchaRef.current.executeAsync();
    const res = await sendRequest(
      'auth/check-recaptcha-token',
      ApiMethods.POST,
      JSON.stringify({
        token,
      }),
      headers,
    );

    return res.statusCode === 201;
  };

  const handleLoginError = (res: any) => {
    setLoading(false);

    // Add a login attempt
    auth.addLoginAttempt();

    // If the response status code is 400, open an error notification
    if (res.statusCode === 400) {
      openErrorNotification(res.message);
      return;
    }

    // If the response status code is 401, set the has error state to true
    if (res.statusCode === 401) {
      setHasError(true);
    }
  };

  const handleFormSubmit = async (values: any): Promise<void> => {
    // Verify the reCAPTCHA token
    const captchaIsValid = await verifyCaptcha();

    if (!captchaIsValid) {
      openErrorNotification('reCAPTCHA check has failed');
      return;
    }

    // Try to login the user
    try {
      setLoading(true);

      // Get the form values
      const { email, staySignedIn, password } = values;

      // Login the user
      const response = await login({ email: email.toLocaleLowerCase(), password });

      // If the login was successful, save the token to cookies and reset the login attempts
      if (!response?.data?.token) {
        handleLoginError(response);
        return;
      }

      // Get the user data from the response
      const { user, userType, is2FaEnabled, token } = response.data;

      // If the user has 2FA enabled and has finished their signup process, store the temporary token and user ID in session storage
      if ((is2FaEnabled.user || is2FaEnabled.client) && user.learnerInformation) {
        sessionStorage.setItem('temporaryToken', token);
        sessionStorage.setItem('userId', user.id);
      }

      // Save the token to cookies and reset the login attempts
      else {
        await saveTokenOnCookies(token);
        auth.storeToken(token, staySignedIn);
        auth.resetLoginAttempts();
      }

      // If the user has not activated their account, return an error
      if (!user.isActivated) {
        setLoading(false);
        openErrorNotification('Your account has not been activated yet. Please check your email for the activation link.');
        return;
      }

      // Add the user type and 2FA status to the user object
      const userWithType = { ...user, type: userType, is2FaEnabled };

      // Only identify user in Heap if 2FA is not enabled
      if (!is2FaEnabled.user && !is2FaEnabled.client) {
        handleHeap(userWithType, cookiePreferences);
      }

      // Set the user object in the Redux store
      dispatch(setUser(userWithType));

      // Move the user forward to the appropriate page
      moveUserForward(userWithType);
    } catch {
      setLoading(false);
      openErrorNotification(ErrorMessage.SOMETHING_WENT_WRONG);
    }
  };

  const errorHandler = () => hasError && setHasError(false);

  const handlePasswordChange = (value: string) => {
    setCurrentPassword(value);
    errorHandler();
  };

  const handleEmailChange = (value: string) => {
    setCurrentEmail(value);
    errorHandler();
  };

  const buttonSelector = 'log-in-button';

  return (
    <>
      <FormWrapper onSubmit={handleFormSubmit} className="FormWrapper">
        <div className="FormWrapperHeader">
          <IdsText component="h1" size="sm" alignment="center">
            Sign in
          </IdsText>
        </div>
        <div className="FormWrapperFieldset">
          <VFQTextField
            id="email-field"
            name="email"
            type="email"
            placeholder="Enter your email address"
            labelName="Email address"
            errorText={hasError ? 'Email or Password is incorrect' : ''}
            changeHandler={handleEmailChange}
            className="is-fullwidth"
            data-elp-testid="sign-in-email-field"
            isRequired
          />
          <VFQPasswordField
            id="password-field"
            name="password"
            placeholder="Enter your password"
            labelName="Password"
            errorText={hasError ? 'Email or Password is incorrect' : ''}
            changeHandler={handlePasswordChange}
            className="is-fullwidth"
            data-elp-testid="sign-in-password-field"
            isRequired
          />
          <IdsFieldWrapper customClasses="is-fullwidth is-stay-signed-in FormWrapperStaySignedIn">
            <IdsCheckbox
              label="Stay signed in"
              idValue="staySignedIn"
              defaultChecked={staySignedIn}
              rest={{ name: 'staySignedIn' }}
              data-elp-testid="stay-signed-in-check-box"
            />
            <IdsLink variant="brand" size="md" isInline href={`/app${AppRoutes.ForgotPassword}`}>
              Forgot Password?
            </IdsLink>
          </IdsFieldWrapper>
          <IdsFieldWrapper customClasses="FormWrapperActions is-fullwidth">
            <IdsButtonGroup position="center">
              <IdsButton
                id={buttonSelector}
                type="submit"
                fullWidth
                isLoading={loading}
                data-elp-testid={slugify(buttonSelector)}
                isDisabled={hasError || !currentEmail || !currentPassword}
              >
                Continue
              </IdsButton>
            </IdsButtonGroup>
            <div className="SignInPageTermsOfUse">
              <TermsOfUse pageName="SignIn" />
            </div>
          </IdsFieldWrapper>
        </div>
      </FormWrapper>
      {showRecaptcha && (
        <ReCAPTCHA className="SignInPageCaptcha" sitekey={captchaSiteKey ?? ''} ref={captchaRef} size="invisible" />
      )}
    </>
  );
};

export default SignInMain;
